import React, { useRef } from "react";
import "./post.css";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import insightData from "../../insights_data";
import Nav from "../../components/Alternate_Navbar/Nav/Nav";
import Navbar from "../../components/Alternate_Navbar/Navbar/Navbar";
import Footer from "../../components/Alternate_Footer/Footer";
import Description from "../../components/Insights/Description/Description";
import Suggestion from "../../components/Insights/Suggestion/Suggestion";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AltDescription from "../../components/Insights/AltDescription/AltDescription";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { useForm, ValidationError } from "@formspree/react";
import Alert from "@mui/material/Alert";
import ReactGA from 'react-ga4';

const Posts = ({ activeTag }) => {
  const location = useLocation();
  const pathLoc = location.pathname.slice(0, 16);
  const phonemouse = useRef();

  const [state, handleSubmit] = useForm("mdorvavr"); //work
  // const [state, handleSubmit] = useForm("mgejqbwd");
  const [alert, setAlert] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");

  function subtract(value, mask) {
    return value
      .split("")
      .filter(function (a, i) {
        return a !== mask[i];
      })
      .join("");
  }
  const indexStr = parseInt(subtract(location.pathname, pathLoc));

  //page information
  const [postData, setPostData] = useState();
  const [postsTags, setPostsTags] = useState([]);

  useEffect(() => {
    insightData?.forEach((data) => {
      if (data.id === indexStr) {
        const post = data;
        const posTag = [];

        post?.tags.forEach((data) => {
          if (data === "projects") {
            posTag.push("Projects");
          }
          if (data === "unboringb2b") {
            posTag.push("Unboring B2B");
          }

          if (data === "b2b") {
            posTag.push("B2B");
          }

          if (data === "branding") {
            posTag.push("BRANDING");
          }

          if (data === "marketingstrategy") {
            posTag.push("Marketing Strategy");
          }

          if (data === "brandidentity") {
            posTag.push("Brand Identity");
          }

          if (data === "website") {
            posTag.push("Website");
          }

          if (data === "rebranding") {
            posTag.push("RE-BRANDING");
          }

          if (data === "corporatecommunication") {
            posTag.push("Corporate Communication");
          }

          if (data === "b2bcommunication") {
            posTag.push("B2B Communication");
          }
        });
        setPostData(post);
        setPostsTags(posTag);
      }
    });
  }, [indexStr]);

  // Function will execute on click of button
  //   const onButtonClick = () => {

  // };

  useEffect(() => {
    setName("");
    setEmail("");
    setNumber(" ");

    // setSubject("");
    if (state.succeeded) {
      setAlert(true);
    }
  }, [state.succeeded]);

  useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Posts" });
  },[]);

  return (
    <>
      <Navbar />
      <div className="whatsapp-sticky-button">
        <div className="floating-message">
          <b>Chat with us</b>
        </div>
        <a
          href="https://wa.me/919820138391?text=Hello TwentytwoAM,%0aI would love it if you call me back at the earliest."
          target="_blank"
        >
          <img
            src={require("../../images/whatsapp.png")}
            className="whatsapp-sticky-img"
            alt="whatsapp"
          />
        </a>
      </div>
      {alert && (
        <Alert
          onClose={() => {
            setAlert(false);
          }}
          className="show-alert"
        >
          Message sent Successfully!
        </Alert>
      )}
      <div className="Posts">
        <div className="insidePostsContainer">
          <div className="posts-return-insight-button">
            <a href="/insights">
              {" "}
              <ArrowBackIcon
                className="insight-back-arrow-posts"
                style={{ marginRight: "10px" }}
              />{" "}
              Insights
            </a>
          </div>
          <div className="posts-tree-container">
            <a href="/insights">Insights</a>{">"}{" "}
            <a
              href={`/insights/posts/${postData?.id}`}
            >{`${postData?.title}`}</a>
          </div>
          <div className="posts-title-container">{postData?.title}</div>
          <div className="posts-tags-container">
            {postsTags?.map((tag) => {
              return (
                <div className="posts-tag" key={tag}>
                  {tag}
                </div>
              );
            })}
          </div>
          <div className="posts-image-container">
            {postData?.image && (
              <img
                src={require(`../../images/insights/${postData?.image}`)}
                alt="posts-image"
                className="posts-image"
              />
            )}
          </div>
          <div className="posts-description-container">
            {postData?.normal === 0 ? (
              <Description
                status={postData?.normal}
                descData={postData?.content}
              />
            ) : (
              <AltDescription descData={postData?.content} />
            )}
          </div>
          {postData?.include === "yes" && (
            <div className="download_pdf_container">
              <a
                href="../../unboringb2b.pdf"
                download="Unboring B2B_Here's to the Crazy.pdf"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div className="download-pdf-button">Download</div>
              </a>
            </div>
          )}
          <div className="post-form-container">
            <div className="post-form-header1-container">Have a project?</div>
            <div className="post-form-header2-container">
              Request a Callback.
            </div>
            <form className="post-contactus-form" onSubmit={handleSubmit}>
              <div className="post-input-container">
                <PersonIcon className="input-icons" />
                <input
                  type="text"
                  className="contactus-input-fields-posts"
                  placeholder="Full Name"
                  name="name"
                  required
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <ValidationError
                  prefix="Name"
                  field="name"
                  errors={state.errors}
                />
              </div>
              <div className="post-input-container">
                <EmailIcon className="input-icons" />
                <input
                  type="email"
                  className="contactus-input-fields-posts"
                  placeholder="Email"
                  name="email"
                  required
                  autoCorrect="false"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </div>
              <div className="post-input-container">
                <LocalPhoneIcon className="input-icons" />
                <PhoneInput
                  type="number"
                  placeholder="Phone Number"
                  country={"in"}
                  // countrySelectProps={{ unicodeFlags: true }}
                  // minLength={10}
                  maxlength="10"
                  pattern="[1-9]{1}[0-9]{9}"
                  // value={number}
                  value={number}
                  required
                  ref={phonemouse}
                  className="contactus-input-fields-posts"
                  onChange={(value) => {
                    setNumber(value);
                  }}
                />
                <input type="hidden" value={number} name="phone"></input>
                <ValidationError
                  prefix="Phone No:"
                  field="phone"
                  errors={state.errors}
                />
              </div>
              <button
                type="submit"
                className="contactus-button-posts"
                disabled={state.submitting}
              >
                Send Message
              </button>
            </form>
          </div>
          <div className="posts-suggestion-container">
            <Suggestion tags={postData?.tags} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Posts;
