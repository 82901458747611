import React from "react";
import "./altdescription.css";
import { Description } from "@mui/icons-material";

const AltDescription = ({ descData }) => {
  return (
    <>
      {descData?.map((data, index) => {
        return data?.type === "description" ? (
          data?.description.map((insole, index) => {
            return (
              <div key={insole}>
                <div
                  className="description"
                  key={insole}
                  dangerouslySetInnerHTML={{ __html: insole }}
                ></div>
              </div>
            );
          })
        ) : (
          <div className={`post_image_alternate`} key={data?.img}>
            <img
              className={`post_image_img ${index%2 === 0 && 'post_image_img-alt'}`}
              src={require(`../../../images/inpost/${data?.img}`)}
              alt={data?.img}
            />
            <div className="post-description-alternate">
              {data?.description.map((insole, index) => {
                return (
                  <div
                    className="description"
                    key={insole}
                    dangerouslySetInnerHTML={{ __html: insole }}
                  ></div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default AltDescription;
