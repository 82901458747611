const insightData = [
    {
        "id":1,
        "normal":0,
        "tags":["b2b","branding","marketingstrategy","b2bcommunication"],
        'image':"sales_force.jpeg",
        'title':"Sales Force Over Band Force",
        "content":[
            "Branding undoubtedly plays a crucial role in establishing a company’s identity. But the tangible impact of sales often takes precedence.",
            "This is why investing in a sales-enabling service or product is given preference by CXOs over a brand-building service or module.",
            "I’d like to articulate it as ‘Sales Force versus Brand Force’",
            "<b>𝗧𝗵𝗲 𝗖𝗵𝗮𝗹𝗹𝗲𝗻𝗴𝗲 𝘄𝗶𝘁𝗵 𝗕𝗿𝗮𝗻𝗱𝗶𝗻𝗴 𝗮𝘀 𝗮 𝗦𝗲𝗿𝘃𝗶𝗰𝗲</b>",
            "Branding’s biggest is that it is often synonymous with logos, graphics, and brochures. This is not wrong, but it isn’t the whole truth either.",
            "Brand service providers often struggle to convey effectiveness in clear and quantifiable terms. While a well-crafted brand is undoubtedly essential for long-term success, the challenge remains in articulating its direct impact on the bottom line.",
            "Thus, sales-enablement services or products are preferred over brand-related ones.",
            "<b>𝗢𝗯𝗷𝗲𝗰𝘁𝗶𝘃𝗲 𝗮𝗻𝗱 𝗧𝗮𝗻𝗴𝗶𝗯𝗹𝗲 𝗥𝗲𝘁𝘂𝗿𝗻𝘀 – 𝗧𝗵𝗲 𝗦𝗮𝗹𝗲𝘀𝗳𝗼𝗿𝗰𝗲 𝗪𝗮𝘆</b>",
            "To make my point further, and to expand on the wordplay, let’s consider Salesforce, the company. It speaks the language of objective metrics and tangible returns. And since data-driven decisions rule, clients increasingly value the concrete outcomes that robust sales solutions can deliver.",
            "Salesforce provides a roadmap with data-backed insights, demonstrating the real value of the organisation’s efforts. It’s about turning leads into conversions, prospects into loyal customers—a narrative grounded in numbers. Needless, to say that Salesforce doesn’t just promise; it quantifies success in measurable terms.",
            "The irony is that Salesforce itself is a meticulously crafted brand. While it is a play of words, ‘Brand Force’ actively drives Salesforce, the company.",
            "<b>𝗧𝗵𝗲 𝗣𝗼𝘄𝗲𝗿 𝗼𝗳 𝗡𝘂𝗺𝗯𝗲𝗿𝘀 – 𝗳𝗼𝗿 𝗖𝗼𝗻𝘃𝗶𝗰𝘁𝗶𝗼𝗻</b>",
            "So clearly, clients want more than aesthetics. They crave results. But, in general, Brand Force has failed to convey its ability to add to the Sales Force convincingly. In the end, it is about converting intent to impact.",
            "In a business landscape where every decision must translate into a positive impact on the balance sheet, the objective nature of ‘brand thinking and brand building’ is still struggling to stand out.",
            "Until the brand is seen as a force that converts intent into measurable impact, and until the numbers begin to do the talking, Sales Force will win over the Brand Force.",
        ],
        "include": 'no',
    },
    {
        "id":2,
        "normal":0,
        "tags":["b2b","branding","b2bcommunication"],
        'image':"shining_bright.jpeg",
        'title':"Shining Bright at Trade Shows",
        "content":[
            "While the holiday season is approaching, the Trade Show season has already begun. 🏁",
            "Trade Shows/Trade Fairs/Expos are a good platform for B2B Brands to be present and showcase their products/services/offerings. 🎙️",
            "Having said that, many brands are offering almost the same products/services. To stand out, brands invest in creating beautiful stalls to attract visitors. 🎪 \nThe major objective here is lead generation. 🚶🏻‍♂️",
            "But there are few brands, which not only focus on beautifying their stalls, but also ensure they give their visitors a beautiful and memorable experience. And that very experience leads to building a strong relation, higher recall and business conversion. 𝐓𝐡𝐢𝐬 𝐡𝐞𝐥𝐩𝐬 𝐛𝐫𝐞𝐚𝐤 𝐭𝐡𝐞 𝐌𝐨𝐧𝐨𝐭𝐨𝐧𝐲. ⬀",
            "This experience starts even before the visitor visits the stall and extends even after the trade show has ended. Different touch points are identified and the communication/experience is crafted accordingly.",
            "For instance, for a B2B Brand to break the monotony and become memorable, it can do the following:",
            "<b>𝐏𝐫𝐞-𝐞𝐯𝐞𝐧𝐭</b>",
            "☼ 𝑈𝑛𝑏𝑜𝑟𝑖𝑛𝑔 Digital Communication through owned media",
            "☼ 𝑈𝑛𝑏𝑜𝑟𝑖𝑛𝑔 Offline communication through sales/marketing teams",
            "<b>𝐄𝐯𝐞𝐧𝐭</b>",
            "☼ 𝑈𝑛𝑏𝑜𝑟𝑖𝑛𝑔 theme/concept",
            "☼ Strategic display of products/services. Ex: AR/VR experience instead of actual products",
            "☼ Engagement activities not directly related to the offerings",
            "☼ 𝑈𝑛𝑏𝑜𝑟𝑖𝑛𝑔 and unexpected takeaways",
            "☼ 𝑈𝑛𝑏𝑜𝑟𝑖𝑛𝑔 audio visual communication",
            "<b>𝐏𝐨𝐬𝐭-𝐞𝐯𝐞𝐧𝐭</b>",
            "☼ 𝑈𝑛𝑏𝑜𝑟𝑖𝑛𝑔 Digital Communication through owned media",
            "☼ 𝑈𝑛𝑏𝑜𝑟𝑖𝑛𝑔 Offline communication through sales/marketing teams",
            "B2B brands need to take bolder steps and find innovative ways to be memorable.",
            "Because, A Brand that is Remembered, is a Brand that is Bought. ✌",
        ],
        "include": 'no',
    },
    {
        "id":3,
        "normal":0,
        "tags":["b2b","marketingstrategy","corporatecommunication","b2bcommunication"],
        'image':"kycc.jpg",
        'title':"KYC to KYCC: A Crucial Shift Needed for B2B Brands",
        "content":[
            "B2B relationships aren’t as simple as a one-on-one. “Know Your Customer” is no longer sufficient. “Know Your Customer’s Customer” (KYCC) is a step up, and a must for B2B brands.",
            "<b>KYC: A Solid Foundation</b>",
            "At its core, KYC lays the foundation for meaningful connections between businesses and its customers. Knowing the customer involves in-depth insights into their needs, preferences, and pain points. This knowledge has long been the key to tailoring products and services to meet the demands of a specific business entity. However, as the business ecosystem continues to evolve, a more comprehensive approach is required.",
            "<b>Time for KYCC</b>",
            "Enter KYCC – a shift that expands the horizon beyond immediate clientele. KYCC represents the commitment to determining, validating, and understanding the characteristics and pursuits of the B2B brand’s customer’s customers. This strategic leap is not just about gathering data; it’s about delving into the intricacies of the end-users who ultimately shape the success of the brand’s client.",
            "<b>Positive Positioning</b>",
            "KYCC goes beyond the traditional product-centric approach. By understanding the end-user, B2B brands can position themselves more positively and impactfully within a target group. Embracing the human elements and emotions inherent in B2B interactions facilitates better storytelling – a powerful tool for engagement that transcends the monotony of product features and pricing discussions.",
            "<b>Beating Jargon in Communication</b>",
            "Who loves an ice cream cone full of jargon-flavoured toppings? KYCC advocates for a more humanized approach, where businesses communicate authentically with a deep understanding of the end-user’s needs. This shift from a mere “product push” to meaningful imagery allows B2B brands to build lasting connections based on shared values and genuine empathy.",
            "<b>Power of Storytelling</b>",
            "Humans connect through stories. By knowing your customer’s customer, B2B brands unlock the potential for compelling narratives that resonate on a personal level. Embracing the human side of B2B relationships fosters a sense of trust and loyalty, as clients perceive the B2B brand as a partner genuinely invested in their success.",
            "<b>Departure from Hollow Claims</b>",
            "KYCC is not about empty promises or hollow wordy claims. It’s about delivering real value by aligning the brand offerings with the actual needs and aspirations of end-users. This authenticity builds credibility, setting the brand apart in a crowded marketplace.",
            "<b>In conclusion</b>, the journey from KYC to KYCC signifies a strategic evolution for B2B brands. It is a recognition that in the era of sophisticated consumers, genuine connection and understanding go a long way.",
            "By embracing KYCC, B2B brands can position themselves as not just providers but partners in the success of their clients and their clients’ customers.",
            "The future of B2B success lies in the depth of understanding, the power of authentic communication, and the ability to tell compelling stories that resonate with the human experience.",
        ],
        "include": 'no',
    },
    {
        "id":4,
        "normal":0,
        "tags":["b2b","marketingstrategy","b2bcommunication"],
        'image':"combo.jpeg",
        'title':"Storytelling + Selling skills = Deadly Combo! 💀",
        "content":[
            "We often get irritated by the fact when we realise that someone is trying to hardsell us something and is being pushy towards it.",
            "But what are great brands doing today? They all are selling, but with the help of storytelling. Brands had realised that direct sales approach or the Push strategy doesn't work always for all of their offerings and to each cohort they are targeting. ",
            "Gone are the days when the Eureka Forbes' guys came to your home and showed how much dust your sofa had. (This was an actual sales strategy but is discontinued for good). But you still have celebrities coming to your house and cleaning your toilets with harpic. (This never occurs in reality, but is used as a marketing tactic). ",
            "Brands of-late have moved to storytelling and have been adding emotions to their communication. They have been adopting the Pull Strategy. Jumping on this trend, some Brands have become just storytellers which has made us smile but actually did not help in getting any business nor any memorability.",
            "The next level where storytelling helps in conversion is when it is combined with sales. The Marketing team has to craft compelling stories for the brand and the sales team has to combine it with their selling skills. ",
            "Then the objectives of memorability and the monies both get achieved.",
            "Signing-off with the belief that 'A Brand that is 𝑟𝑒𝑚𝑒𝑚𝑏𝑒𝑟𝑒𝑑 is a Brand that is 𝑏𝑜𝑢𝑔ℎ𝑡' ...",
            "...and for that you need to focus on Story𝐬𝐞𝐥𝐥𝐢𝐧𝐠 instead of just Story𝐭𝐞𝐥𝐥𝐢𝐧𝐠",
            "Though Consumer-facing brands have been practicing this since sometime, B2B Brands too have realised the importance of this approach and have started using it as a part of their strategies.",
        ],
        "include": 'no',
    },
    {
        "id":5,
        "normal":0,
        "tags":["b2b","unboringb2b"],
        'image':"crazy.jpeg",
        'title':"Here's to the Crazy",
        "content":[
            "B2B businesses have been very clinical and bland in their efforts to build brands. So to say, it's 'meh-diocracy' (meh and mediocre). Unboring B2B, thus, has become our war cry.",
            "But it's not to be seen as a buzzword, but something that's rooted in strategic thinking that can result in 'creative, impactful, nudging and memorable' brand-building efforts.",
            "To that effect, as we continue on our 'Unboring B2B' overdrive, it gives us immense pleasure to share a read that has been lovingly crafted to celebrate B2B brands who've been doing great work, and to inspire B2B brands to look ahead into 2024 to be a 'bit more crazy'. Here's to the Crazy!",
            "Hope you enjoy the read. ✌",
        ],
        "include": 'yes',
    },
    // {
    //     "id":6,
    //      "normal":"yes",
    //     "tags":["b2b","unboringb2b",'branding',"marketingstrategy"],
    //     'image':"threat.jpg",
    //     'title':"Disguised Threats",
    //     "content":[
    //         "Have you ever heard about 𝐃𝐢𝐬𝐠𝐮𝐢𝐬𝐞𝐝 𝐓𝐡𝐫𝐞𝐚𝐭𝐬?",
    //         "Well, these are threats which go unnoticed most of the times by the businesses or brands. These are not direct competition to the businesses or brands.",
    //         "But those who do a deep culture study, tend to find out and work towards it. Some find a solution to counter these disguised threats, while others turn it into opportunities and use it as a part of their communication.",
    //         "This an important part of consumer/customer psychology, behaviours, habits and cultures.",
    //         "Read on to see certain disguised threats presented here in different contexts for different brands, businesses or industries.",
    //         "Try to think of some disguised threats in your industry, business or for your brand.",
    //     ],
    //     "include": 'no',
    // },
    {
        "id":6,
         "normal":1,
        "tags":["b2b","unboringb2b",'branding',"marketingstrategy"],
        'image':"threats.jpeg",
        'title':"Disguised Threats",
        "content":[
            {   
                'type':"description",
                'description':["Have you ever heard about 𝐃𝐢𝐬𝐠𝐮𝐢𝐬𝐞𝐝 𝐓𝐡𝐫𝐞𝐚𝐭𝐬?"],
            },
            {   
                'type':"description",
                'description':["Well, these are threats which go unnoticed most of the times by the businesses or brands. These are not direct competition to the businesses or brands, but act as threats more from the POV of psychology and behaviours."],
            },
            {   
                'type':"description",
                'description':["Brands, Businesses, Industries and even Governments have to deal with these. They are not directly visible but create a huge impact."],
            },
            {   
                'type':"description",
                'description':["But those who do a deep culture study, tend to find out and work towards it. Some find a solution to counter these disguised threats, while others turn it into opportunities and use it as a part of their communication."],
            },
            {   
                'type':"description",
                'description':["This an important part of consumer/customer psychology, behaviours, habits and cultures. "],
            },
            {   
                'type':"description",
                'description':["Let’s see some examples of disguised threats for Brands, Businesses, and Industries at large."],
            },
            {   
                'type':"description",
                'description':["<u><b>Disclaimer:</b> While few are based on facts, most are personal suggestions/interpretations</u>"],
            },
            {
                'type':"combined",
                'img':'threats/threat1.png',
                'description': ["If you thought that brands like Coca-Cola have a threat from Pepsi or a Red Bull faces threat from other energy drinks like Monster, think again.",
                "While these may be direct competition to these brands, the disguised threats are water and fresh coconut water respectively. These are hidden threats, but loom over when it comes to consumer psychology."],
            },
            {
                'type':"combined",
                'img':'threats/threat2.png',
                'description': ["We all love binge-watching our favourite shows or eagerly await for that next episode or season, we tend to compromise a little on our sleep or we are ready to skip that book we want to read for the time being.",
                "That’s exactly what these OTTs have as disguised threats. They want you to watch more and thus keep getting and suggesting the content you like. If you choose to sleep or read instead, they might lose business."],
            },
            {
                'type':"combined",
                'img':'threats/threat3.png',
                'description': ["In India, traditionally, celebration = mithai/sweets. Cadbury’s Dairy Milk chocolate wanted the traditional mithai to be replaced. And we know it has been able to achieve this to some extent. But, still mithai happens to be a disguised threat for Dairy Milk.",
                "Sneakers on other hand positioned itself as a quick hunger snack. In this game, all the quick snacks and bites become a disguised threat."],
            },
            {
                'type':"combined",
                'img':'threats/threat4.png',
                'description': ["While Chai/tea is the answer to most of the life problems of us Indians, adrak wali chai with more super ingredients like tulsi (basil), laung (clove), lemon grass, etc. becomes a remedy for headaches.",
                "Brands like Saridon or Disprin, who claim to provide quick relief for small headaches, adrak wali chai becomes a strong opponent with super natural powers."],
            },
            {
                'type':"combined",
                'img':'threats/threat5.png',
                'description': ["When was the last time you received candy from that neighbourhood shopkeeper instead of the change for your money? Since the pandemic, the UPI transactions have soared like anything. And with it, the change/chutta system just had a hard stop!",
                "This incurred a huge loss for the candy business in India. Who would have thought that our humble and user-friendly UPI could become a threat to anyone?"],
            },
            {
                'type':"combined",
                'img':'threats/threat6.png',
                'description': ["It’s a fact that most of the love is showered on babies and dogs/puppies than anyone else in this world.","But who would have thought that those cute puppies could become a threat to the Baby Products industry…",
                "Couples these days want to become pet parents and are happy with the unconditional love, thus affecting the baby products industry at large. If you still think that this is not a threat that big, watch the animated movie ‘The Boss Baby’ and you will understand."],
            },
            {
                'type':"combined",
                'img':'threats/threat7.png',
                'description': ["Well, the image is self-explanatory. Specially in the Indian context.","Most of the singles are still looking for commitments and are serious when it comes to relationships. The final result is marriage, mostly post which the search for a good partner ends, and thus dating stops.",
                "While, one may say that matrimonial portals can also be threats, but when it comes to behaviours and psychology, the services of a dating app may not be required anymore."],
            },
            {
                'type':"combined",
                'img':'threats/threat8.png',
                'description': ["Soap bars are not going anywhere soon. Though some of us might have shifted to body wash liquids, handwash liquids, soaps are here to stay.",
                "But, what use a soap would be if there is no water. Draught conditions make people realise the importance of water conservation and usage habits, thus resulting in minimal use of soaps and also detergents to some extent."],
            },
            {
                'type':"combined",
                'img':'threats/threat9.png',
                'description': ["We will have lots of answers when it comes to finding threats that face our economy. We can go in the zone of sustainability, working conditions, etc, and the list might just go on."],
            },
            {
                'type':"combined",
                'img':'threats/threat10.png',
                'description': ["But when it comes to disguised threats, the rise in the number of cyclists/bicyclists can become a threat, ever wondered why?",
                "Increased cyclists means the first industry to be affected is the automobile and affiliated industries. Other industries that could be affected with this are the Petroleum Industry and the Healthcare Industry. People will get more fitter and less prone to diseases, affecting the revenue of the Healthcare industry as well as fitness industry."],
            },
            {
                'type':'description',
                'description':["Thus, any business, a brand or an industry could be facing these disguised threats. A deeper understanding of psychology, behaviours, mindsets and culture will help in decoding and help turn the threat into an opportunity."],
            },
        ],
        "include": 'no',
    },
]

export default insightData;